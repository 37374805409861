<template>
    <crud-index-component v-bind="$data">
        <template slot="header">
            {{ $t('crud.users._plural') }}
        </template>
    </crud-index-component>
</template>

<script>
    import CrudIndexComponent from "../crud/Index";
    import {config} from '../crud/mixin_config';

    export default {
        name: 'users-index-component',
        components: {CrudIndexComponent},
        data() {
            return {
                crud: config('users'),
                fields: [{
                    title: this.$t('crud.id'),
                    name: 'id',
                    sortField: 'id'
                }, {
                    title: this.$t('crud.users.username'),
                    name: 'username',
                    sortField: 'username'
                }, {
                    title: this.$t('crud.users.email'),
                    name: 'email',
                    sortField: 'email'
                }, {
                    title: this.$t('crud.users.name'),
                    name: '',
                    formatter: (record) => {
                        return (record.first_name || '') + ' ' + (record.last_name || '');
                    }
                }, {
                    title: this.$t('crud.created_at'),
                    'name': 'created_at',
                    formatter: (val) => {
                        if(val) {
                            return this.$options.filters.date(val);
                        }
                    }
                }, {
                    title: this.$t('crud.actions'),
                    name: 'slot-actions'
                }]
            };
        },
        methods: {}
    }
</script>
